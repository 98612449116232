html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  font-family: "Radio Canada Big", sans-serif;
  overflow-x: hidden;
}

body.hidden {
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: #fff;
  text-decoration: none;
}

button {
  background-color: transparent;
  border: none;
  color: #fff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.modal-wrapper {
  width: 670px !important;
}

.modal-wrapper.pending {
  width: 450px !important;
}

.modal-wrapper.modal_wallet,
.modal-wrapper.modal_node {
  width: 400px !important;
}

.modal-wrapper .ant-modal-content {
  background-color: #191919;
}

.modal-wrapper .ant-modal-close svg {
  fill: #fff;
}

.modal-wrapper .ant-modal-content::before,
.modal-wrapper .ant-modal-content::after {
  content: "";
  position: absolute;
  pointer-events: none;
}

.modal-wrapper .ant-modal-footer {
  margin-top: 0;
}

@media screen and (max-width: 767px) {
  text {
    stroke: none !important;
    stroke-width: 0 !important;
  }
  .rd3t-node {
    stroke: none !important;
    stroke-width: 0 !important;
  }
  .modal-wrapper .ant-modal-content {
    padding-left: 10px;
    padding-right: 10px;
  }
}